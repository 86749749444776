import { Container } from 'theme-ui';
import { useSettings } from '@backpackjs/storefront';

export const HomeTemplate = ({ page, renderSections, ...props }) => {
  const sections = renderSections();
  const visibleSections = sections?.filter(
    (section) => !section?.props?.hidden
  );
  const settings = useSettings();
  const {
    enableTransparentBackgroundDesktop,
    enableTransparentBackgroundMobile,
  } = { ...settings?.header };
  return (
    <Container
      data-comp="HomeTemplate"
      sx={{
        mt: [
          enableTransparentBackgroundMobile ? '-53px' : 0,
          null,
          enableTransparentBackgroundDesktop ? '-57px' : 0,
        ], // needed for transparent background for nav
      }}
    >
      {visibleSections}
    </Container>
  );
};
